import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { JsonLd } from "../components/JsonLd"
import Moment from "react-moment"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Directory from "../components/directory/directory"
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  RedditShareButton,
  RedditIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share"

const Resource = ({ resource, meta }) => {
  return (
    <>
      <Helmet
        title={resource.title}
        titleTemplate={`%s | ${meta.title}`}
        meta={[
          {
            property: "og:type",
            content: "article",
          },
          {
            name: "description",
            content: `${resource.description}`,
          },
          {
            property: "og:url",
            content: `${meta.url}/resource-links/${resource.slug}`,
          },
          {
            property: "og:title",
            content: `${resource.title} | TIVP`,
          },
          {
            property: "og:description",
            content: `${resource.description}`,
          },
          {
            property: "og:image:secure_url",
            content: `${resource.featuredImage.url}`,
          },
          {
            property: "og:image",
            content: `${resource.featuredImage.url}`,
          },
          {
            property: "og:image:width",
            content: `${resource.featuredImage.width}`,
          },
          {
            property: "og:image:height",
            content: `${resource.featuredImage.height}`,
          },
          {
            name: "twitter:card",
            content: "summary_large_image",
          },
          {
            name: "twitter:creator",
            content: "@tivp_org",
          },
          {
            name: "twitter:site",
            content: "@tivp_org",
          },
          {
            name: "twitter:title",
            content: `${resource.title} | TIVP`,
          },
          {
            name: "twitter:description",
            content: `${resource.description}`,
          },
          {
            name: "twitter:image",
            content: `${resource.featuredImage.url}`,
          },
        ].concat(meta)}
      >
        <html lang="en" />
        <JsonLd>
          {{
            "@context": "http://schema.org/",
            "@type": "Article",
            publisher: {
              "@type": "Organization",
              name: `${meta.organization.name}`,
              logo: {
                "@type": "ImageObject",
                url: `${meta.logo}`,
              },
            },
          }}
        </JsonLd>
      </Helmet>
      <Layout>

        <div className="w-full laptop:flex-row px-4 desktop:px-0 bg-white phone:py-12 py-24">
          <div className="flex justify-center content-center">
            <div className="w-full laptop:w-3/5 desktop:w-auto 4k:w-1/4 flex justify-center prose prose-li:list-none">
              <div className="w-full phone:px-4 tablet:px-4 px-8">
                <div className="w-full flex flex-col">
                  <div className="uppercase text-4xl text-stone-700 text-center desktop:text-left font-oswald font-semibold mb-2">
                    {resource.title}
                  </div>
                </div>

                <div className="w-full flex justify-center">
                  <div className="w-full flex flex-col">
                    <div className="w-full h-auto flex justify-start phone:mt-2 phone:my-4 mt-4 mb-8">
                      <div className="h-full w-full border border-gray-400">
                        <GatsbyImage
                          image={resource.featuredImage.gatsbyImageData}
                          className="aspect-w-16 aspect-h-9 shadow-md shadow-gray-800/20"
                          alt={resource.title}
                        />
                      </div>
                    </div>

                    <div className="w-full flex flex-col desktop:flex-row">
                      <div className="w-full py-2 desktop:py-6 desktop:pt-0">
                        <article
                          className="prose 4k:text-lg text-stone-600 font-roboto prose-green max-w-none selection:bg-green-200 pb-6 
                          prose-blockquote:border prose-blockquote:border-blue-600 prose-blockquote:bg-blue-600 prose-blockquote:text-slate-100
                          prose-blockquote:py-4 prose-blockquote:text-xl prose-blockquote:shadow-lg prose-blockquote:rounded prose-blockquote:text-center"
                          dangerouslySetInnerHTML={{
                            __html: resource.content.html,
                          }}
                        />
                        <div className="border-t border-b border-gray-100">
                          {resource.socialMedia.length ? (
                            <div className="p-2">
                              <div className="text-oswald font-bold underline">
                                Social Media
                              </div>
                              <div>
                                {resource.socialMedia.map((social, i) => (
                                  <div className="font-roboto text-stone-700" key={i}>
                                    <a
                                      href={social}
                                      target="_blank"
                                      rel="noreferrer"
                                      referrerpoilicy="strict-origin-when-cross-origin"
                                      className="text-green-600"
                                    >
                                      {social}
                                    </a>
                                  </div>
                                ))}
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}

                          {resource.websites.length ? (
                            <div className="p-2">
                              <div className="text-oswald font-bold underline">
                                Websites
                              </div>
                              <div>
                                {resource.websites.map((websites, i) => (
                                  <div className="font-roboto text-stone-700" key={i}>
                                    <a
                                      href={websites}
                                      target="_blank"
                                      rel="noreferrer"
                                      referrerpoilicy="strict-origin-when-cross-origin"
                                      className="text-green-600"
                                    >
                                      {websites}
                                    </a>
                                  </div>
                                ))}
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}

                          {resource.email ? (
                            <div className="pt-2 pb-6">
                              <div className="text-oswald font-bold underline">Email</div>
                              <div className="font-roboto text-stone-700">
                                <a
                                  href={`mailto:${resource.email}`}
                                  className="text-green-600"
                                >
                                  {resource.email}
                                </a>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}

                          {resource.resourceBooks.length ? (
                            <div className="p-2">
                              <div className="text-oswald font-bold underline">Books</div>
                              <div>
                                {resource.resourceBooks.map((book, i) => (
                                  <li className="font-roboto text-stone-700" key={i}>
                                    <a
                                      href={book.link}
                                      target="_blank"
                                      rel="noreferrer"
                                      referrerpoilicy="strict-origin-when-cross-origin"
                                      className="text-green-600"
                                    >
                                      {book.title}
                                    </a>
                                  </li>
                                ))}
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="w-full phone:flex tablet:flex flex-col hidden print:hidden">
                      <div className="w-full flex flex-row justify-center items-center mt-10 border-gray-300">
                        <TwitterShareButton
                          title={`${resource.title} | The Infinite Voice Project`}
                          caption={resource.description}
                          url={`https://theinfinitevoiceproject.org/resource-links/${resource.slug}`}
                        >
                          <TwitterIcon size="10x" round={false} />
                        </TwitterShareButton>
                        <FacebookShareButton
                          quote={resource.description}
                          url={`https://theinfinitevoiceproject.org/resource-links/${resource.slug}`}
                        >
                          <FacebookIcon size="10x" round={false} />
                        </FacebookShareButton>
                        <RedditShareButton
                          title={`${resource.title} | The Infinite Voice Project`}
                          url={`https://theinfinitevoiceproject.org/resource-links/${resource.slug}`}
                        >
                          <RedditIcon size="10x" round={false} />
                        </RedditShareButton>
                        <EmailShareButton
                          subject={`${resource.title} | The Infinite Voice Project`}
                          body={resource.description}
                          url={`https://theinfinitevoiceproject.org/resource-links/${resource.slug}`}
                        >
                          <EmailIcon size="10x" round={false} />
                        </EmailShareButton>
                        <WhatsappShareButton
                          title={`${resource.title} | The Infinite Voice Project`}
                          url={`https://theinfinitevoiceproject.org/resource-links/${resource.slug}`}
                        >
                          <WhatsappIcon size="10x" round={false} />
                        </WhatsappShareButton>
                      </div>
                    </div>

                    {/*<div className="w-full phone:flex tablet:flex flex-col desktop:pt-0 border-t border-gray-300 hidden print:hidden">
                      <RecentArticles />
                    </div>
                    */}
                  </div>
                </div>
              </div>
            </div>
            {/*<div className="w-1/5 flex phone:hidden tablet:hidden phone:mt-0 tablet:mt-0 -mt-4 px-8">
              <RecentArticles />
            </div>
            */}
          </div>
        </div>
        <Directory />
      </Layout>
    </>
  )
}

const Article = ({ data }) => {
  const resourceContent = data.graphCmsResource
  const meta = data.site.siteMetadata
  if (!resourceContent) return null
  return <Resource resource={resourceContent} meta={meta} />
}

export default Article

Resource.propTypes = {
  resource: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
}

export const query = graphql`
  query ResourceTenplateQuery($id: String!) {
    graphCmsResource(id: { eq: $id }) {
      title
      id
      socialMedia
      websites
      slug
      email
      description
      updatedAt
      resourceBooks {
        title
        slug
        link
      }
      content {
        html
        text
      }
      featuredImage {
        gatsbyImageData(
          placeholder: BLURRED
          layout: FULL_WIDTH
          aspectRatio: 2
        )
      }
    }
    site {
      siteMetadata {
        title
        description
        author
        url
        logo
        organization {
          name
        }
      }
    }
  }
`
